import React, { createContext, useContext } from 'react';

import gql from 'graphql-tag';
import { useMeQuery, MeQuery } from '../types';
import type { ApolloError, ApolloQueryResult } from '@apollo/client';

type User = NonNullable<MeQuery['me']>;
type Optional<T, K extends keyof T> = Pick<Partial<T>, K> & Omit<T, K>;

interface ContextValue
  extends Optional<User, 'id' | 'oid' | 'phone' | 'scopes'> {
  userLoading?: boolean;
  fetchUser: () => Promise<ApolloQueryResult<MeQuery>>;
  fetchError: ApolloError | undefined;
}

const initialState: ContextValue = {
  scopes: undefined,
  canManage: true,
  fetchUser: () => new Promise(resolve => resolve),
  name: undefined,
  email: undefined,
  fetchError: undefined,
};

export const UserContext = createContext<ContextValue>(initialState);

const GET_ME = gql`
  query Me {
    me {
      id
      oid
      scopes
      canManage
      name
      phone
      email
    }
  }
`;

const UserProvider: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  const {
    loading: userLoading,
    data,
    refetch: fetchUser,
    error,
  } = useMeQuery({
    fetchPolicy: 'network-only',
  });
  const user = data?.me || {
    scopes: undefined,
    canManage: true,
    name: undefined,
    email: undefined,
  };

  return (
    <UserContext.Provider
      value={{
        ...user,
        userLoading,
        fetchUser,
        fetchError: error,
      }}
    >
      {children}
    </UserContext.Provider>
  );
};

export const useUser = () => useContext(UserContext);

export default UserProvider;
